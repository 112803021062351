const firebaseConfigsDev = {
  apiKey: "AIzaSyARNm0c7GjcdgbcBK3mQSoLlKycv829O50",
  authDomain: "robomart-ai-dev.firebaseapp.com",
  projectId: "robomart-ai-dev",
  storageBucket: "robomart-ai-dev.appspot.com",
  messagingSenderId: "998623905821",
  appId: "1:998623905821:web:e37ee4af1c0945082d9ef4",
  measurementId: "G-YCWDV02ZLM"
};

export default firebaseConfigsDev;
